<div style="height: 60px;"></div>

<p style="font-family: cursive">
Contact:


Regd. Office 	       : Plot No. 3258, Chintamaniswar temple área  Lane 3, Bhubaneswar, Orissa, India. PIN - 751014
<br><br>Correspondence Address :Flat no.: B/402, Aviskar Apartment
                            <br>Jagannath Nagar Lane -1, Jharpada 
                            <br>Bhubaneswar, Orissa, India. PIN - 751025
<br><br>Phone                  : 	+91 79780 35487, +91 94371 28225, +91 94372 69965
<br><br>Email 	               :    creativeinfotech6@gmail.com 
<br><br>Website	               :    creativeinfotech.org.in
</p>
