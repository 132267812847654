<!-- <nav class="navbar navbar-inverse navbar-fixed-top" style="background-color:maroon">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" href="#">Creative Computer Education</a> 
      <img src="assets/image/CI_logo.png" alt="" width="150" height="50">
    </div>
    <ul class="nav navbar-nav">
      <!-- class="active" 
      <li><a routerLink="/ci-home-component" routerLinkActive="active">Home</a></li>
      <li><a routerLink="#" routerLinkActive="active">About Us</a></li>
      <li><a routerLink="#" routerLinkActive="active">Contact Us</a></li>
    </ul>
  </div>
</nav> -->

<nav class="navbar navbar-inverse navbar-fixed-top" >
    <div class="container">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>                        
        </button>
        <!-- <a class="navbar-brand" href="#">Creative Computer Education</a>  -->
        <a class="navbar-brand" href="#"><img src="assets/image/CI_logo.png" alt="" width="150" height="50"></a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav navbar-right">
          <li><a routerLink="/ci-aboutus-component" routerLinkActive="active">ABOUT</a>
            <!-- <a href="#about">ABOUT</a>--></li> 
          <li><a routerLink="/ci-team-component" routerLinkActive="active">TEAM</a></li>
          <!-- <li><a href="#portfolio">PORTFOLIO</a></li>
          <li><a href="#pricing">PRICING</a></li> -->
          <li><a routerLink="/ci-contactus-component" routerLinkActive="active">CONTACT</a></li>
        </ul>
      </div>
    </div>
  </nav>

<!-- The sidebar -->
<!-- <div class="sidebar">
  <img src="assets/image/Banner.png" width="200px" height="100px" >
  <a routerLink="/aboutus-component" routerLinkActive="active">About Us</a>
  <a routerLink="/courses-component" routerLinkActive="active">Course Offered</a>
  <a routerLink="/contact-component" routerLinkActive="active">Contact Us</a>
</div> -->

<!-- Page content -->
<!-- <div class="content"> style="margin: 20px; margin-bottom: 20px; background-color:white; background-image: cross-fade()" --> 
  <div  class="container pt-3" >
    <router-outlet>
    </router-outlet>
</div>