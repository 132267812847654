<div style="height: 60px;"></div>
<!-- <section id="content">
        <div class="container">
    
          <div class="row">
            <div class="span12">
              <div class="row">
                <div class="span4">
                  <div class="box flyLeft animated fadeInLeftBig">
                    <div class="icon">
                      <i class="fas fa-laptop ico icon-circled icon-bgdark icon-laptop active icon-3x" style='font-size:36px;color:white'></i>
                    </div>
                    <div class="text">
                      <h4 class="">Quality <strong>Training</strong></h4>
                      <p>
                        CCE is a leading computer training institute. 
                        CCE was started in the year 2020 and 
                        today more than 100 students study in our centres.
                      </p>
                      <a routerLink="/courses-component" routerLinkActive="active">Learn More</a>
                    </div>
                  </div>
                </div> 
    
                <div class="span4">
                  <div class="box flyIn animated fadeInUp">
                    <div class="icon">
                      <i class="fa fa-shield ico icon-circled icon-bgdark icon-shield active icon-3x" style='font-size:36px;color:white'></i>
                    </div>
                    <div class="text">
                      <h4 class="">Our <strong> Strength</strong></h4>
                      <p>
                        The courses at CCE are designed according 
                        to the latest trends in the software industry. 
                        Hence the curriculum is regularly revised so that the students 
                        do not end up learning something that is outdated.
                        <br><br>
                        The teachers add to our strength with their dedication and commitment to impart 
                        their knowledge to the learners in their classes. 
                        Finally, what sets CCE apart from the rest is the ambience. 
                        We provide an atmosphere of discipline, friendliness and professionalism 
                        that touches the learning spirit of every student.
                      </p>
                      <a routerLink="/aboutus-component" routerLinkActive="active">Learn More</a>
                    </div>
                  </div>
                </div>
                <div class="span4">
                  <div class="box flyRight animated fadeInRightBig">
                    <div class="icon">
                      <i class=" fa fa-star ico icon-circled icon-bgdark icon-star active icon-3x" style='font-size:36px;color:white'></i>
                    </div>
                    <div class="text">
                      <h4 class="">Dedicated <strong>Faculty</strong></h4>
                      <p>
                        Teachers at CCE are experienced and dedicated. 
                        The Lab and Class room environment is condusive to Learning.
                      </p>
                      <!-- <a href="#">Learn More</a>
                      <a routerLink="/aboutus-component" routerLinkActive="active">Learn More</a>
                    </div>
                  </div>
                </div>
    
              </div>
            </div>
          </div>
    
          <div class="row">
            <div class="span12">
              <div class="solidline"></div>
            </div>
          </div>
        </div>
      </section> -->

<div class="container p-3 my-3  border" style="font-family: cursive !important">
        <h3>Creative Infotech</h3>
        <p><em>We love doing social work!</em></p>
        <p>Creative InfoTech is a Trust registered under The
                Indian Trusts Act, 1882 bearing number of 1385
                dated 1 st October 2009 in Sub-register office,
                Bhubaneswar, District-Khurda working towards in the
                areas of development in order to quality of life of
                the deprived backward and disadvantages people.</p>
        <br>
        <hr>
        <div class="row">
          <div class="col-sm-6" style="font-family: cursive !important">
            <img src="assets/image/AIM.svg" alt="AIM" width="50" height="50" class="rounded"  style="background-color: darkturquoise"> 
            <!-- <p><strong>AIM</strong></p><br> -->
            <!-- <div class="icon">
                    <i class="fas fa fa-crosshairs ico icon-circled icon-bgdark icon-laptop active icon-3x" style='font-size:36px;color:white'></i>
                  </div> -->
            <p class="fw-bolder fst-italic" > To establish, run, manage and administer development activities and to
                    develop social networking without any distinctive of any caste or colour
                    to improve the quality of life.</p>
          </div>
          <div class="col-sm-6" style="font-family: cursive !important; ">
            <img src="assets/image/objective.svg" alt="Objective" width="50" height="50">
            <!-- <p><strong>Objective</strong></p><br> -->
            <!-- <p><i class="fa-li fa fa-check-square"></i>Facilitate community building and related activities through propagation of various development programme and especially community oriented technical projects. 
                    <br><br>Set up and establish various development centres in rural areas to work as co-ordination centres to promote development initiatives.
                    <br><br>Undertake ventures in articulating the artisan and forming them into groups/ guilds and graduate them to an entrepreneurial mode with emphasis on empowering women groups. 
                    <br><br>Implement development programmes through application of appropriate technology and Science.
                    <br> <br>Develop different varieties of computerized software’s for Govt., NGOs, and Civil Society Organisations for different domains/ purposes. 
                    </p> -->

                    <ul class="fa-ul">
                            <li><i class="fa-li fa fa fa-hand-o-right fa-2x"></i>Facilitate community building and related activities through propagation of various development programme and especially community oriented technical projects. </li>
                            <br><br><li><i class="fa-li fa fa fa-hand-o-right fa-2x"></i>Set up and establish various development centres in rural areas to work as co-ordination centres to promote development initiatives.</li>
                            <br><br><li><i class="fa-li fa fa fa-hand-o-right fa-2x"></i>Undertake ventures in articulating the artisan and forming them into groups/ guilds and graduate them to an entrepreneurial mode with emphasis on empowering women groups.</li>
                            <br><br><li><i class="fa-li fa fa fa-hand-o-right fa-2x"></i>Implement development programmes through application of appropriate technology and Science.</li>
                            <br><br> <li><i class="fa-li fa fa fa-hand-o-right fa-2x"></i>Develop different varieties of computerized software’s for Govt., NGOs, and Civil Society Organisations for different domains/ purposes. </li>
                          </ul>
            <!-- <img src="assets/image/Picture4.png" alt="Random Name" width="100%">  -->
          </div>
          <!-- <div class="col-sm-4">
            <p><strong>Name</strong></p><br>
            <img src="bandmember.jpg" alt="Random Name">
          </div>-->
        </div>
</div>
