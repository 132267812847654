import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CiHomeComponent } from './ci-home/ci-home.component';
import { CiAboutusComponent } from './ci-aboutus/ci-aboutus.component';
import { CiContactusComponent } from './ci-contactus/ci-contactus.component';
import { CiTeamComponent } from './ci-team/ci-team.component';

const routes: Routes = [ 
  { path: '',   redirectTo: '/ci-home-component', pathMatch: 'full' },
  { path: 'ci-home-component', component: CiHomeComponent },
  { path: 'ci-aboutus-component', component: CiAboutusComponent },
  { path: 'ci-contactus-component', component: CiContactusComponent },
  { path: 'ci-team-component', component: CiTeamComponent },
  { path: 'Home', component: CiHomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
