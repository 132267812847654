
<div style="height: 60px;"></div>
<div class="row" style="font-family: cursive;">

        <!-- <div class="container">
                <img src="/assets/image/team1.jpg" alt="Avatar" class="image">
                <div class="overlay">
                  <div class="text">Hello World</div>
                </div>
              </div> -->

        <div class="column">
          <div class="card">
            <img src="/assets/image/team1.jpg" alt="Tripuramohini" width="100%">
            <div class="container">
              <h2>Tripuramohini Satapathy</h2>
              <p class="title">Managing Director</p>
              <p>MSW in Sociology and <br>MSc in Zoology (Environmental Science)</p>
              <p>Mob: +91 94371 28225, Email: tripuramohini@gmail.com</p>
              <!-- <p><button class="button">Contact</button></p> -->
            </div>
          </div>
        </div>
      
        <div class="column">
          <div class="card">
            <img src="/assets/image/team2.jpg" alt="Ram" width="100%">
            <div class="container">
              <h2>Ramachandra Panda</h2>
              <p class="title">Member</p>
              <p>Civil Engineer and MBA in Project Management</p>
              <p>Mob: +91 94372 69965 <br>Email: ramchandra.panda2@gmail.com  </p>
              <!-- <p><button class="button">Contact</button></p> -->
            </div>
          </div>
        </div>
        
        <div class="column">
          <div class="card">
            <img src="/assets/image/team3.jpg" alt="Chinmaya" width="100%">
            <div class="container">
              <h2>Chinmaya Panda</h2>
              <p class="title">Member</p>
              <p>MCA, MBA in Project Management </p>
              <p>Mob: +91 9861562485 <br>Email: panda.chinmaya@gmail.com </p>
              <!-- <p><button class="button">Contact</button></p> -->
            </div>
          </div>
        </div>
      </div>
