import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ci-aboutus',
  templateUrl: './ci-aboutus.component.html',
  styleUrls: ['./ci-aboutus.component.css']
})
export class CiAboutusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
