
<div style="height: 60px;"></div>

<div class="jumbotron text-center">
    <h1>Creative Infotech</h1> 
    <p>Creative InfoTech is a Trust registered under The
        Indian Trusts Act, 1882 bearing number of 1385
        dated 1 st October 2009 in Sub-register office,
        Bhubaneswar, District-Khurda working towards in the
        areas of development in order to quality of life of
        the deprived backward and disadvantages people.</p> 
    <!-- <form>
      <div class="input-group">
        <input type="email" class="form-control" size="50" placeholder="Email Address" required>
        <div class="input-group-btn">
          <button type="button" class="btn btn-danger">Subscribe</button>
        </div>
      </div>
    </form> -->
  </div>
  <div id="myCarousel" class="carousel slide text-center" data-ride="carousel" style="margin-top: 38px;">
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>
  
    <div class="carousel-inner" >
      <div class="item active">
        <img src="assets/image/1.jpg" alt="Parking Survey" style="width:100%; height: 400px" >
        <div class="carousel-caption">
          <h3>Bhubaneswar</h3>
          <p>Parking Survey in KIIT Area</p>
        </div>
      </div>
  
      <div class="item">
        <img src="assets/image/2.jpeg" alt="Parking Survey" style="width:100%; height: 400px">
        <div class="carousel-caption">
            <h3>Bhubaneswar</h3>
            <p>Parking Survey in Master Canteen Area</p>
          </div>
      </div>
  
      <div class="item">
        <img src="assets/image/3.jpeg" alt="Parking Survey" style="width:100%; height: 400px">
        <div class="carousel-caption">
            <h3>Bhubaneswar</h3>
            <p>RNI Survey in KIIT Area</p>
          </div>
      </div>
    </div>
  
    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

    
    
    